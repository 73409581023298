import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  image?: ImageProps
  title?: string
}

export const Visual = memo(function Visual({ image, title }: Props) {
  if (!image || !title) {
    return null
  }

  return (
    <Container dial={5} tag="section">
      {image ? <Image {...image} /> : null}
      {title ? <Title>{title}</Title> : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  height: 66.7vh;
  max-height: 37.5rem;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 767px) {
    height: 50vh;
    margin-bottom: 3.75rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 2.6875rem;
  opacity: 0.5;
  position: relative;
  z-index: 2;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 767px) {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  @media (max-width: 767px) {
    padding: 0 1.25rem;
  }
`
